import { defineStore } from 'pinia'

import axios from "@/plugins/axios"

require('dotenv').config()
const VUE_APP_ENDPOINT_URL = process.env.VUE_APP_ENDPOINT_URL

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore('user', {
  actions: {
		index: () => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/users`),
		password: (id, { password, password_confirmation }) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/users/${id}/password`, { password, password_confirmation }),
		show: (id) => axios.get(`${VUE_APP_ENDPOINT_URL}/api/admin/users/${id}`),
		store: (user) => axios.post(`${VUE_APP_ENDPOINT_URL}/api/admin/users`, user),
		update: (id, user) => {
			const payload = { ...user }
			payload.active = (payload.active == 'Active')
			payload.fingerprint_bypass = (payload.fingerprint_bypass == 'Yes')

			return axios.patch(`${VUE_APP_ENDPOINT_URL}/api/admin/users/${id}`, payload)
		},
		updateFingerprint: (id, fingerprint) => {
			const payload = { fingerprint }

			return axios.put(`${VUE_APP_ENDPOINT_URL}/api/admin/users/${id}/fingerprint`, payload)
		},
		
  }
})
