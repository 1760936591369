<script setup>

import { ref } from "vue"
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast"
import { useAuthStore } from "@/store/auth"
import { useUserStore } from "@/store/user"

const confirm = useConfirm()
const toast = useToast()
const authStore = useAuthStore()
const userStore = useUserStore()

const users = ref([])
const loading = ref(true)

const confirmCancelFingerprint = (data) => {
	confirm.require({
		message: 'Are you sure you want to clear fingerprint?',
		header: `Name: ${data.name}`,
		accept: () => {
      userStore.updateFingerprint(data.id, null).then(() => {
      const index = users.value.findIndex((user) => user.id === data.id)
        toast.add({ severity: "success", summary: "Success", detail: `${data.name}'s fingerprint cancelled`, life: 3000 })
        users.value[index].fingerprint = null
      })
		}
	});
}

const search = () => {
  loading.value = true

  userStore.index().then((res) => {
    users.value = res.data.users

    loading.value = false
  })
}

search()

</script>

<template>
  <div class="grid">
    <div class="col-12">
      <div class="card">
        <h5>Users <Button label="Create" icon="pi pi-plus" class="p-button-sm ml-3" @click="$router.push({ name: 'admins.create' })" /></h5>
        <DataTable 
          class="p-datatable-gridlines" 
          dataKey="id" 
          filterDisplay="row"
          :loading="loading" 
          :paginator="true" 
          responsiveLayout="scroll"
          :rowHover="true" 
          :rows="10"
          :value="users"
        >
          <template #header>
            <div class="flex justify-content-between flex-column sm:flex-row" />
          </template>
          <template #empty>
              No records.
          </template>
          <template #loading>
              Loading. Please wait.
          </template>

          <Column field="role" header="Role">
            <template #body="{data}">
              <Chip :label="data.role" :class="data.role == 'owner' ? 'custom-chip-owner' : 'custom-chip-admin'" />
            </template>
          </Column>

          <Column field="name" header="Name" style="min-width:12rem">
            <template #body="{data}">
              <span style="margin-left: .5em; vertical-align: middle" class="image-text">{{ data.name }}</span>
            </template>
          </Column>

          <Column field="username" header="Username" style="min-width:14rem">
            <template #body="{data}">
              {{ data.username }}
              <Tag v-if="! data.active" class="ml-3" icon="pi pi-times" severity="danger" value="Deactive" />
            </template>
          </Column>

          <Column header="Options">
            <template #body="{data}">
              <router-link :to="`/admins/${data.id}/edit`">
                <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning mr-2 mb-2" />
              </router-link>

              <router-link v-if="authStore.hasPermission('owner') || authStore.hasPermission('user-statistics')" :to="`/admins/${data.id}/statistic`">
                <Button icon="pi pi-chart-bar" class="p-button-rounded p-button-info mr-2 mb-2" />
              </router-link>

              <Button v-if="data.fingerprint" icon="pi pi-lock" class="p-button-rounded p-button-success mr-2 mb-2" @click="confirmCancelFingerprint(data)"/>
            </template>
          </Column>

        </DataTable>
      </div>
    </div>
  </div>
</template>


<style scoped lang="scss">
@import '@/assets/demo/styles/badges.scss';

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}

::v-deep(.p-progressbar) {
  height: .5rem;
  background-color: #D8DADC;

  .p-progressbar-value {
    background-color: #607D8B;
  }
}

.p-chip.custom-chip-owner {
    background: var(--pink-600);
    color: var(--primary-color-text);
}
.p-chip.custom-chip-admin {
    background: var(--blue-600);
    color: var(--primary-color-text);
}
</style>
